import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputTextarea} from 'primereact/inputtextarea'
import {Dropdown} from 'primereact/dropdown'
import {Checkbox} from 'primereact/checkbox'
import {Calendar} from 'primereact/calendar'
import {Attachments} from '../../components/Attachments'
import {TabView,TabPanel} from 'primereact/tabview'
import '../../components/Forms.css'


class RequestForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'request', '/Demandes', 'Needs',

      {
        request: {
          id: props.match.params.id,
          description: "",
          proposedResume: "",
          sentResume: "",
          metCandidate: "",
          recurrent: false,
          startDate: new Date (),
          state: "",
          state_id: 0,
          contact_id: (props.match.params.object ? parseInt (props.match.params.object, 10) : 0),
          contact_firstName: "",
          contact_lastName: "",
          contact_name: "",
          inCharge_id: 0,
          inCharge_firstName: "",
          inCharge_lastName: "",
          company_name: "",
        },

        mandatoryFields: {
          description: {test: FormContainer.checkNotEmpty, state: false},
        },

        inCharge_list: [],
        state_list: [],
      }
    )

  }



  /*
   * Form data management
   */

  async getExternalValues () {

    // Populate inCharge dropdown selector options
    const inCharge_list = await this.apiCall ( 'Users', 'list')
    inCharge_list.values = inCharge_list.values.map (u => {return {name: u.code + ' (' + u.firstName + ' ' + u.lastName + ')', id: u.id}})
    inCharge_list.values.push ({id: 0, name: 'Tous (Tous les collaborateurs)'})


    /*
    // Retrieve ORM object informations from contact id
    //  commonName = human full name of the Item ("ex: Microsoft corporation")
    let objectInfos = { commonName: "" }
    if ( !this.state.request.contact_name && this.state.request.contact_id ) {
      objectInfos = await this.apiCall ( 'Needs', 'getObjectInfos', this.state.request.contact_id)
    }
    */

    // Populate type dropdown selector options
    const state_list = await this.apiCall ('NeedStates', 'list')

    // Setup values
    await this.setState (
      prevState => {
        prevState.request.inCharge_id = prevState.request.inCharge_id || 0
        //prevState.request.contact_name = prevState.request.contact_name || objectInfos.commonName
        return {
          inCharge_list: inCharge_list.values,
          state_list: state_list.values,
          request: prevState.request,
        }
      }
    )
  }


  cleanUpValues (values) {
    // Dates are received encoded as an ISO 8601 string then converted to date object
    values.startDate = new Date (values.startDate)
  }

  doRender() {
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Demande client {this.state.request.company_name}</h1>
                <FormContainer
                  values={this.state.request}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >
                  <TabView>

                    <TabPanel header="Informations" rightIcon="pi pi-info">
                      <div className="form-new-line p-grid">


                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="startDate">Date de début</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Calendar
                              className="form-input"
                              disabled={true}
                              id="startDate"
                              value={this.state.request.startDate}
                              onChange={this.handleCalendar}
                              placeHolder=""
                              showIcon={true}
                              readOnlyInput={true}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange="1900:2100"
                              dateFormat="dd/mm/yy"
                              locale={FormContainer.localDate('fr')}
                            ></Calendar>
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-4">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="state">Etat</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={!this.state.editMode}
                              id="state"
                              options={this.state.state_list.map (c => ({label: c.name, value: c.id}))}
                              value={this.state.request.state_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div >

                        <div className="p-col-12 p-grid p-md-4">
                          <div className="p-col-12">
                          </div>
                          <div className="p-col-11 form-text-align-md-right">
                            <label htmlFor="recurrent">Besoin récurrent</label>
                          </div>
                          <div className="p-col-1">
                            <Checkbox
                              className="form-input"
                              disabled={true}
                              inputId="recurrent"
                              value="recurrent"
                              onChange={this.handleCheckBox}
                              checked={this.state.request.recurrent}
                            />
                          </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-6">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="inCharge">Responsable</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <Dropdown
                              className="form-input"
                              disabled={true}
                              id="inCharge"
                              options={this.state.inCharge_list.map (c => ({label: c.name, value: c.id}))}
                              value={this.state.request.inCharge_id}
                              onChange={this.handleExternalSelect}
                              autoWidth={false}
                            />
                          </div >
                        </div >

                        <div className="p-col-12 p-grid">
                          <div className="p-col-12">
                            <MandatoryLabel htmlFor="description">Description</MandatoryLabel>
                          </div>
                          <div className="p-col-12">
                            <InputTextarea
                              rows={5}
                              cols={80}
                              autoResize={true}
                              className="form-input"
                              id="description"
                              disabled={true}
                              value={this.state.request.description}
                              onChange={this.handleInputText}
                            />
                          </div>
                        </div>

                        <div className="p-col-12 p-grid">
                          <div className="p-col-12">
                            <label htmlFor="proposedResume">Candidats Proposés</label>
                          </div>
                          <div className="p-col-12">
                            <InputTextarea
                              rows={5}
                              cols={80}
                              autoResize={true}
                              className="form-input"
                              id="proposedResume"
                              disabled={!this.state.editMode}
                              value={this.state.request.proposedResume}
                              onChange={this.handleInputText}
                            />
                          </div>
                        </div>

                        <div className="p-col-12 p-grid">
                          <div className="p-col-12">
                            <label htmlFor="sentResume">CVs positionnés</label>
                          </div>
                          <div className="p-col-12">
                            <InputTextarea
                              rows={5}
                              cols={80}
                              autoResize={true}
                              className="form-input"
                              id="sentResume"
                              disabled={true}
                              value={this.state.request.sentResume}
                              onChange={this.handleInputText}
                            />
                          </div>
                        </div>

                        <div className="p-col-12 p-grid">
                          <div className="p-col-12">
                            <label htmlFor="metCandidate">Présentations de Candidats</label>
                          </div>
                          <div className="p-col-12">
                            <InputTextarea
                              rows={5}
                              cols={80}
                              autoResize={true}
                              className="form-input"
                              id="metCandidate"
                              disabled={true}
                              value={this.state.request.metCandidate}
                              onChange={this.handleInputText}
                            />
                          </div>
                        </div>

                      </div>
                    </TabPanel>

                    <TabPanel header="Pièces jointes" rightIcon="pi pi-paperclip">
                      <div className="form-new-line p-grid">
                        <div className="p-col-12 form-new-line">
                          <label htmlFor="attachments">Pièces jointes associées :</label>
                        </div>
                        <div className="p-col-12">
                          <Attachments
                            id="attachments"
                            parentId={this.state.request.id}
                            locked={this.state.editMode}
                            getValues={this.getAttachmentsValues}
                            getVersions={this.getAttachmentVersions}
                            setVersions={this.setAttachmentVersions}
                            delete={this.deleteAttachment}
                            doUpload={this.fileUpload}
                            doDownload={this.fileDownload}
                            watch={this.state.profile}
                          >
                          </Attachments>
                        </div>
                      </div>
                    </TabPanel>

                  </TabView>
                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RequestForm

