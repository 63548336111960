import React from 'react'
import {FilterService} from '../../services/FilterService'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'

export class NeedTable extends AuthenticatedTable {

  constructor(props) {
    super (
      props, 'need', '/Besoins', 'Needs',
      {
        state_list: [],
        inCharge_list: [],
      }
    )
    this.getInCharge_list = FilterService.getInCharge_list.bind(this)
    this.setInChargeFilter = FilterService.setInChargeFilter.bind(this)
    this.setReferenceTeamFilter = FilterService.setReferenceTeamFilter.bind(this)
  }

  async getValues (first=0, rows=0, sort=null, filter=null, filters=null) {
      // Set access filter
      filters = this.setInChargeFilter (filters)
      filters = this.setReferenceTeamFilter (filters)
      // Get results
      const needs = await super.getValues (first, rows, sort, filter, filters)
      // Map inchard_id = 0 to anybody
      return FilterService.mapInChargeAnyBody (needs)
  }

  async getExternalValues () {

    // Get users inCharge
    const inCharge_list = await this.getInCharge_list ()

    // Get needs states
    const state_list = await this.apiCall ('NeedStates', 'list')
    state_list.values = state_list.values.map (t => {return {label: t.name, value: t.id, ...t}})

    this.setState (
      {
        state_list: state_list.values,
        inCharge_list: inCharge_list.values
      }
    )
  }


  doRender() {
    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}
        noAutoFocus={this.props.noAutoFocus}
        onOpen={this.handleOpen}
        onDelete={this.handleDelete }
        deleteTitle="Archiver"
        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column key="company_name" field="company_name" header="Entreprise" sortable={true} style={{width:'21%'}}/>
        <Column key="contact_name" field="contact_name" header="Client" sortable={true} style={{width:'22%'}}/>
        <Column key="description" field="description" header="Description" sortable={true} style={{width:'30%'}} body={LazyDataTable.maxLinesTemplate(3)}/>
        <Column
          key="state"
          field="state"
          header="Etat"
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.state_list}
          sortable={true}
          style={{width:'15%'}}
          body={LazyDataTable.optionTemplate}
        />
        <Column
          key="startDate"
          field="startDate"
          header="Début"
          filterMethod={LazyDataTable.dateFilter}
          sortable={true}
          style={{width:'1%'}}
          body={LazyDataTable.dateTemplate}
        />
        <Column
          key="recurrent"
          field="recurrent"
          header=<i className="fas fa-history"></i>
          filterMethod={LazyDataTable.optionsFilter}
          filterParams={LazyDataTable.booleanDefaultOptions}
          sortable={false}
          style={{textAlign:'center', width:'3%'}}
          body={LazyDataTable.booleanTemplate}
        />
        <Column
          key="inCharge"
          field="inCharge"
          header=<i className="pi pi-user"/>
          sortable={true}
          style={{width:'4%'}}
          filterMethod={LazyDataTable.multiOptionsFilter}
          filterParams={this.state.inCharge_list}
        />
        <Column
          key="proposedResume"
          //if={this.state.profile.expert}
          header="Candidats Proposés"
          field="proposedResume"
          style={{width:'1%'}}
          body={LazyDataTable.notesTemplate}
          filterMethod={LazyDataTable.noFilter}
        />
        <Column
          key="sentResume"
          //if={this.state.profile.expert}
          header="CVs positionnés"
          field="sentResume"
          style={{width:'1%'}}
          body={LazyDataTable.notesTemplate}
          filterMethod={LazyDataTable.noFilter}
        />
        <Column
          key="metCandidate"
          //if={this.state.profile.expert}
          header="Présentations de Candidats"
          field="metCandidate"
          style={{width:'1%'}}
          body={LazyDataTable.notesTemplate}
          filterMethod={LazyDataTable.noFilter}
        />
      </LazyDataTable>
    )
  }
}

