import {Keys} from '../keys/Keys'
import {Users} from '../users/Users'
import {Boards} from '../boards/Boards'
import {Teams} from '../teams/Teams'
import {Companies} from '../companies/Companies'
import {Needs} from '../needs/Needs'
import {Requests} from '../requests/Requests'
import {NeedStates} from '../needs/NeedStates'
import {Applicants} from '../applicants/Applicants'
import {ApplicantOrigins} from '../applicants/ApplicantOrigins'
import {ApplicantStates} from '../applicants/ApplicantStates'
import {ApplicantNextSteps} from '../applicants/ApplicantNextSteps'
import {Events} from '../events/Events'
import {EventTypes} from '../events/EventTypes'
import {Contacts} from '../contacts/Contacts'
import {PersonalContacts} from '../personalContacts/PersonalContacts'
import {PersonCivilities} from '../persons/PersonCivilities'
import {PersonRoles} from '../persons/PersonRoles'
import {Mobilities} from '../persons/Mobilities'
import {Degrees} from '../persons/Degrees'
import {EnglishLevels} from '../persons/EnglishLevels'
import {Experiences} from '../persons/Experiences'
import {LegalStatutes} from '../persons/LegalStatutes'
import {Mailings} from '../mailings/Mailings'
import {MailingStates} from '../mailings/MailingStates'
import {MailingTemplates} from '../mailings/MailingTemplates'
import {Imports} from '../imports/Imports'

export class MenuItems {
  /*
   * AppRegister fields :
   *  MENU :
   *  - menu:         Item menu path ex: /Tableau de bord
   *  - icon:         Item menu icon ex: fas fa-fw fa-home
   *  - show:         Manu display validation function ex: () => this.state.profile.unit === 'hr'
   *  ROUTE :
   *  - route:        Route activating this item ex : /Tableau de bord commercial
   *  - component:    Javascript component implementting this item ex: Boards
   *  - default:      This is the default page for the application ex: true
   *  - access:       Access validation functoin ex: () => this.state.profile.globalManager
   *  SERVICE (only used when calling object from another like an event)
   *  - name:         Human name of this kind of entity ex: Entreprise
   *  - service:      The WebService of this object ex: PersonalContacts
   */

  static async registerAppComponents () {

    // Main board
    // Sales people
    if ( this.state.profile.unit === 'sales') {
      await this.appRegister (
        {
          menu: '/Tableau de bord',
          icon: 'fas fa-fw fa-home',
          route: '/Tableau de bord commercial',
          component: Boards,
          default: true,
        }
      )
    } else if ( this.state.profile.unit === 'hr') {
      await this.appRegister (
        {
          menu: '/Tableau de bord',
          icon: 'fas fa-fw fa-home',
          route: '/Tableau de bord RH',
          component: Boards,
          default: true,
        }
      )
    }

    // Personal contacts
    await this.appRegister (
      {
        name: 'Contact',
        menu: '/Répertoire',
        icon: 'far fa-fw fa-address-card',
        route: '/Contacts',
        service: 'PersonalContacts',
        component: PersonalContacts,
      }
    )

    // Companies
    await this.appRegister (
      {
        name: 'Entreprise',
        menu: '/Entreprises',
        icon: 'far fa-fw fa-building',
        route: '/Entreprises',
        service: 'Companies',
        component: Companies,
        access: () => this.state.profile.unit === 'sales' || (this.state.profile.globalManager && this.state.profile.expert)
      }
    )

    // Applicants
    await this.appRegister (
      {
        menu: '/Candidats',
        icon: 'a-fw fas fa-user-graduate',
      },
    )
    await this.appRegister (
      {
        menu: '/Candidats/Rencontrés',
        icon: 'fa-fw fas fa-user-friends',
        route: '/Candidats-rencontrés',
        component: Applicants,
      }
    )
    await this.appRegister (
      {
        menu: '/Candidats/Vivier',
        icon: 'fa-fw fas fa-user-tag',
        route: '/Candidats-vivier',
        component: Applicants,
      }
    )
    await this.appRegister (
      {
        menu: '/Candidats/Embauchés',
        icon: 'fa-fw fas fa-user-check',
        route: '/Candidats-embauchés',
        component: Applicants,
      }
    )
    await this.appRegister (
      {
        menu: '/Candidats/Importés',
        icon: 'fa-fw fas fa-user-plus',
        route: '/Candidats-importés',
        component: Applicants,
      }
    )
    await this.appRegister (
      {
        name: 'Candidats',
        menu: '/Candidats/Tous',
        icon: 'fa-fw fas fa-users',
        route: '/Candidats',
        service: 'Applicants',
        component: Applicants,
      }
    )

    // RH nNeeds
    await this.appRegister (
      {
        menu: '/Demandes',
        icon: 'fas fa-fw fa-cart-plus',
        route: '/Demandes',
        service: 'Needs',
        component: Requests,
        show: () => this.state.profile.unit === 'hr'
      }
    )

    // Contacts
    await this.appRegister (
      {
        name: 'Contact',
        route: '/Interlocuteurs',
        service: 'Contacts',
        component: Contacts,
      }
    )

    // Events
    // Menu events is very dynamic because it refer to modulable object
    // It may have submenu or be direct

    // Set route for forms
      await this.appRegister (
        {
          route: '/Evénements',
          service: 'Events',
          component: Events,
        }
      )
    // Events submenus for manager and sales people
    if ( this.state.profile.unit === 'sales' || this.state.profile.globalManager ) {
      await this.appRegister (
        {
          menu: '/Evénements',
          icon: 'fa-fw fas fa-tasks',
          show: () => this.state.profile.unit === 'sales' || this.state.profile.globalManager
        },
      )
      await this.appRegister (
        {
          name: 'Rappel',
          menu: '/Evénements/Clients',
          icon: 'fa-fw fas fa-user-tie',
          route: '/Evénements/Clients',
          service: 'Events',
          component: Events,
          show: () => this.state.profile.unit === 'sales' || this.state.profile.globalManager
        }
      )
      await this.appRegister (
        {
          name: 'Rappel',
          menu: '/Evénements/Candidats',
          icon: 'fa-fw fas fa-user-graduate',
          route: '/Evénements/Candidats',
          service: 'Events',
          component: Events,
          show: () => this.state.profile.unit === 'sales' || this.state.profile.globalManager
        }
      )
    } else if (this.state.profile.unit === 'hr') {
      await this.appRegister (
        {
          name: 'Rappel',
          menu: '/Evénements',
          icon: 'fa-fw fas fa-tasks',
          route: '/Evénements/Candidats',
          service: 'Events',
          component: Events,
          show: () => this.state.profile.unit === 'hr'
        }
      )
    }

    // Sales Needs
    await this.appRegister (
      {
        menu: '/Besoins',
        icon: 'fas fa-fw fa-cart-plus',
        route: '/Besoins',
        service: 'Needs',
        component: Needs,
        show: () => this.state.profile.unit === 'sales' || (this.state.profile.globalManager && this.state.profile.expert)
      }
    )

    // Mailings
    await this.appRegister (
      {
        name: 'Mailings',
        menu: '/Mailings',
        icon: 'fa-fw fas fa-envelope',
        route: '/Mailings',
        service: 'Mailings',
        component: Mailings,
        //access: () => this.state.profile.unit === 'sales' || (this.state.profile.globalManager && this.state.profile.expert)
      }
    )

    // Imports
    await this.appRegister (
      {
        name: 'Imports',
        menu: '/Imports',
        icon: 'fa-fw fas fa-file-upload',
        route: '/Imports',
        service: 'Imports',
        component: Imports,
      }
    )



    // -------------------------------------------------------------------------------------------------------



    // Configuration
    await this.appRegister (
      {
        menu: '/Configuration',
        icon: 'pi pi-fw pi-cog',
        show: () => this.state.profile.expert
      },
    )

    // Configuration / Users
    await this.appRegister (
      {
        name: 'Utilisateur',
        menu: '/Configuration/Utilisateurs',
        icon: 'pi pi-fw pi-user',
        route: '/Utilisateurs',
        service: 'Users',
        component: Users,
        //access: () => this.state.profile.globalManager && this.state.profile.expert
        access: () => this.state.profile.expert
      }
    )

    // Configuration / teams
    await this.appRegister (
      {
        menu: '/Configuration/Equipes',
        icon: 'pi pi-fw pi-users',
        // TMP DEV -----------------------  access: () => { return this.state.profile.expert }
        access: () => this.state.profile.expert
      }
    )

    // Configuration / teams / Organigram
    await this.appRegister (
      {
        name: 'Equipes',
        menu: '/Configuration/Equipes/Organigramme',
        icon: 'fa-fw fas fa-sitemap',
        route: '/Equipes/Organigramme',
        component: Teams,
      }
    )

    // Configuration / teams / List
    await this.appRegister (
      {
        name: 'Equipes',
        menu: '/Configuration/Equipes/Liste',
        icon: 'fa-fw fas fa-bars',
        route: '/Equipes/Liste',
        component: Teams,
      }
    )

    //--------------------------------------------------------------------------------------------------------------

    // Configuration / Archives
    await this.appRegister (
      {
        menu: '/Configuration/Archives',
        icon: 'pi pi-fw pi-save',
        access: () => this.state.profile.globalManager && this.state.profile.expert
      },
    )

    // Configuration / Archives / Users
    await this.appRegister (
      {
        menu: '/Configuration/Archives/Utilisateurs',
        icon: 'pi pi-fw pi-user',
        route: '/Archives/Utilisateurs',
        component: Users,
      }
    )

    // Configuration / Archives / Teams
    await this.appRegister (
      {
        menu: '/Configuration/Archives/Equipes',
        icon: 'pi pi-fw pi-users',
        route: '/Archives/Equipes',
        component: Teams,
      }
    )

    // Configuration / Archives / Companies
    await this.appRegister (
      {
        menu: '/Configuration/Archives/Entreprises',
        icon: 'far fa-fw fa-building',
        route: '/Archives/Entreprises',
        component: Companies,
      }
    )

    // Configuration / Archives / Contacts
    await this.appRegister (
      {
        menu: '/Configuration/Archives/Interlocuteurs',
        icon: 'fas fa-fw fa-user-tie',
        route: '/Archives/Interlocuteurs',
        component: Contacts,
      }
    )

    // Configuration / Archives / Applicants
    await this.appRegister (
      {
        menu: '/Configuration/Archives/Candidats',
        icon: 'fa-fw fas fa-user-graduate',
        route: '/Archives/Candidats',
        component: Applicants,
      }
    )

    // Configuration / Archives / Modèles de mailing
    await this.appRegister (
      {
        menu: '/Configuration/Archives/Modèles de mailing',
        icon: 'fa-fw fas fa-feather',
        route: '/Archives/Mailing/Modèles',
        component: MailingTemplates,
        access: () => this.state.profile.globalManager && this.state.profile.expert
      },
    )

    // Configuration / keys
    if ( this.context.config.twoFactorsMethod ) {
      await this.appRegister (
        {
          name: 'Clé',
          menu: '/Configuration/Gestion des clés',
          icon: 'pi pi-fw pi-key',
          route: '/Clées',
          component: Keys,
          access: () => this.state.profile.globalManager && this.state.profile.expert
        },
      )
    }

    // Configuration / Mailings
    await this.appRegister (
      {
        name: 'Mailings',
        menu: '/Configuration/Modèles de mailing',
        icon: 'fa-fw fas fa-feather',
        route: '/Mailing/Modèles',
        component: MailingTemplates,
        access: () => this.state.profile.globalManager && this.state.profile.expert
      },
    )


    // ------------------------------------------------------------------------------------------------------------

    // Configuration / Data
    await this.appRegister (
      {
        menu: '/Configuration/Données',
        icon: 'fa fa-fw fa-database',
        access: () => this.state.profile.globalManager && this.state.profile.expert
      },
    )

    // Configuration / Data / Events
    await this.appRegister (
      {
        menu: '/Configuration/Données/Evénements',
        icon: 'pi pi-fw pi-cog',
      },
    )

    // Configuration / Data / Events / Sales Types
    await this.appRegister (
      {
        menu: '/Configuration/Données/Evénements/Types clients',
        icon: 'far fa-fw fa-list-alt',
        route: '/Types/Evénements/sales',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: EventTypes,
      }
    )

    // Configuration / Data / Events / Staffing Types
    await this.appRegister (
      {
        menu: '/Configuration/Données/Evénements/Types candidats',
        icon: 'far fa-fw fa-list-alt',
        route: '/Types/Evénements/hr',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: EventTypes,
      }
    )

    /*
     * USELESS DEPRECATED !
    // Configuration / Data / Events / Types génériques
    await this.appRegister (
      {
        menu: '/Configuration/Données/Evénements/Types génériques',
        icon: 'far fa-fw fa-list-alt',
        route: '/Types/Evénements/eventtype',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: EventTypes,
      }
    )
    */

    // Configuration / Data / Applicants
    await this.appRegister (
      {
        menu: '/Configuration/Données/CVthèque',
        icon: 'pi pi-fw pi-cog',
      },
    )

    // Configuration / Data / Applicants / Origins
    await this.appRegister (
      {
        menu: '/Configuration/Données/CVthèque/Origines',
        icon: 'far fa-fw fa-list-alt',
        route: '/Origines/CVthèque',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: ApplicantOrigins,
      }
    )

    // Configuration / Data / Applicants / States
    await this.appRegister (
      {
        menu: '/Configuration/Données/CVthèque/Etats',
        icon: 'far fa-fw fa-list-alt',
        route: '/Etats/CVthèque',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: ApplicantStates,
      }
    )

    // Configuration / Data / Applicants / Steps
    await this.appRegister (
      {
        menu: '/Configuration/Données/CVthèque/Etapes',
        icon: 'far fa-fw fa-list-alt',
        route: '/Etapes/CVthèque',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: ApplicantNextSteps,
      }
    )

    // Configuration / Data / Needs
    await this.appRegister (
      {
        menu: '/Configuration/Données/Besoins',
        icon: 'pi pi-fw pi-cog',
      },
    )

    // Configuration / Data / Needs / States
    await this.appRegister (
      {
        menu: '/Configuration/Données/Besoins/Etats',
        icon: 'far fa-fw fa-list-alt',
        route: '/Etats/Besoins',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: NeedStates,
      }
    )

    // Configuration / Data / Mailings
    await this.appRegister (
      {
        menu: '/Configuration/Données/Mailings',
        icon: 'pi pi-fw pi-cog',
      },
    )

    // Configuration / Data / Mailings / States
    await this.appRegister (
      {
        menu: '/Configuration/Données/Mailings/Etats',
        icon: 'far fa-fw fa-list-alt',
        route: '/Etats/Mailings',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: MailingStates,
      }
    )

    // Configuration / Data / Mailings / Parameters
    await this.appRegister (
      {
        menu: '/Configuration/Données/Mailings/Paramètres',
        icon: 'fa-fw fas fa-wrench',
        route: '/Paramétres/Mailings',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: MailingStates,
      }
    )

    // Configuration / Data / Persons
    await this.appRegister (
      {
        menu: '/Configuration/Données/Personnes',
        icon: 'pi pi-fw pi-cog',
      },
    )

    // Configuration / Data / Persons / Civilities
    await this.appRegister (
      {
        menu: '/Configuration/Données/Personnes/Civilitées',
        icon: 'far fa-fw fa-list-alt',
        route: '/Personnes/Civilitées',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: PersonCivilities,
      }
    )

    // Configuration / Data / Persons / Roles
    await this.appRegister (
      {
        menu: '/Configuration/Données/Personnes/Roles',
        icon: 'far fa-fw fa-list-alt',
        route: '/Personnes/Roles',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: PersonRoles,
      }
    )

    // Configuration / Data / Persons / Mobilities
    await this.appRegister (
      {
        menu: '/Configuration/Données/Personnes/Mobilités',
        icon: 'far fa-fw fa-list-alt',
        route: '/Personnes/Mobilités',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: Mobilities,
      }
    )

    // Configuration / Data / Persons / Degrees
    await this.appRegister (
      {
        menu: '/Configuration/Données/Personnes/Diplômes',
        icon: 'far fa-fw fa-list-alt',
        route: '/Personnes/Diplômes',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: Degrees,
      }
    )

    // Configuration / Data / Persons / EnglishLevels
    await this.appRegister (
      {
        menu: "/Configuration/Données/Personnes/Niveaux d'anglais",
        icon: 'far fa-fw fa-list-alt',
        route: "/Personnes/Niveaux d'anglais",
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: EnglishLevels,
      }
    )

    // Configuration / Data / Persons / Experiences
    await this.appRegister (
      {
        menu: '/Configuration/Données/Personnes/Expériences',
        icon: 'far fa-fw fa-list-alt',
        route: '/Personnes/Expériences',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: Experiences,
      }
    )

    // Configuration / Data / Persons / Legal Statutes
    await this.appRegister (
      {
        menu: '/Configuration/Données/Personnes/Statuts juridiques',
        icon: 'far fa-fw fa-list-alt',
        route: '/Personnes/Statuts juridiques',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: LegalStatutes,
      }
    )

    // Configuration / Data / Imports
    await this.appRegister (
      {
        menu: '/Configuration/Données/Imports',
        icon: 'pi pi-fw pi-cog',
      },
    )

    // Configuration / Data / Imports / Types
    await this.appRegister (
      {
        menu: '/Configuration/Données/Imports/Types',
        icon: 'far fa-fw fa-list-alt',
        route: '/Types/Imports',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: Imports,
      }
    )

    // Configuration / Data / Imports / States
    await this.appRegister (
      {
        menu: '/Configuration/Données/Imports/Etats',
        icon: 'far fa-fw fa-list-alt',
        route: '/Etats/Imports',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: Imports,
      }
    )

    // Configuration / Data / Imports / Parameters
    await this.appRegister (
      {
        menu: '/Configuration/Données/Imports/Paramètres',
        icon: 'fa-fw fas fa-wrench',
        route: '/Paramétres/Imports',
        access: () => this.state.profile.globalManager && this.state.profile.expert,
        component: Imports,
      }
    )

    return true
  }
}

